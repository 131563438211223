<template>
	<label :class="['tn-label', size, { disabled }, { dark }]">
		<slot />
	</label>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * TnLabel is a reusable helper for use in other components where you would typically use the label-tag
 * @displayName TnLabel
 */
export default defineComponent({
	name: "TnLabel",

	props: {
		/**
		 * Size of the label
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Dark
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Disabled
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/scss/mixins" as mixins;

.tn-label {
	--text-color: #{variables.$color-neutrals-700-shade};

	color: var(--text-color);

	@include mixins.font-text-bold-s;

	&.s {
		@include mixins.font-text-bold-xs;
	}

	&.l {
		@include mixins.font-text-bold-m;
	}

	&.disabled {
		--text-color: #{variables.$color-neutrals-400-tint};
	}

	&.dark {
		--text-color: #{variables.$color-neutrals-200-tint};

		&.disabled {
			--text-color: #{variables.$color-neutrals-300-tint};
		}
	}
}
</style>
